@import url('https://fonts.googleapis.com/css?family=Sulphur+Point&display=swap');

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;

}

#root {
  overflow: hidden;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  background: #0c0f13;
  overflow: hidden;
  font-family: 'sulphur point', 'avenir next', avenir,
    'helvetica neue', helvetica, ubuntu, roboto, noto, 'segoe ui', arial,
    sans-serif;
  color: white;
}

.overlay {
  position: absolute;
  inset: 0;
  background-color: rgba(12, 15, 19, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  position: absolute;
  top: 2rem;
  left: 0rem;
  bottom: 0rem;
  z-index: 999;
}

h1 {
  font-size: 3rem;
  line-height: 1;
  margin: 0 0 1rem 0;
  text-align: center;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: rgb(239, 239, 239);
}

.soon {
  color: #fff;
  border-radius: 4px;
  padding: 1rem 2rem;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-size: 1.2rem;
  line-height: 1;
  margin: 0 auto;
  max-width: fit-content;
  animation: opacityPulse 2s ease-out;
  animation-iteration-count: infinite;
  opacity: 1;
}

.social {
  display: flex;
  gap: 1em;
}

.spinner-wrapper {
  display: flex;
  width: 100vw;
  height: 100vh;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.spinner {
  position: relative;
  width: 80px;
  height: 80px;
}

.spinner::before {
  position: absolute;
  border: 4px solid #d998ee;
  border-radius: 50%;
  opacity: 0;
  animation: ripple 1.5s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  content: '';
}

.spinner::after {
  position: absolute;
  border: 4px solid #d998ee;
  border-radius: 50%;
  opacity: 0;
  animation: ripple 1.5s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  animation-delay: 1.1s;
  content: '';
}

.attribution {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  left: 1rem;
  padding-left: 3rem;
  padding-right: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  font-size: 0.75rem;
  color: #c6bff9;
  opacity: 0.75;
}

a {
  text-decoration: none;
  font-size: 0.75rem;
  color: #737373;
}

a:hover {
  color: #d998ee;
}

@keyframes opacityPulse {
  0% {
    opacity: 0.2;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.2;
  }
}

@keyframes ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

@media only screen and (max-width: 768px) {
  .logo {
    top: 1rem;
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .attribution {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}